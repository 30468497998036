import { translatedMonthAndYear } from 'shared/modules/DateTimeUtils';
import dayjs from 'dayjs';
import _ from 'lodash';
import i18next from 'i18next';

const balanceSelector = (balance) => {
  return {
    totalIncome: balance.total_income / 100,
    monthIncome: balance.month_income / 100,
    percent: balance.percent,
  };
};

const payoutsSelector = (payouts) => {
  // eslint-disable-next-line no-underscore-dangle
  const payoutsArray = payouts._embedded?.items ?? [];
  const data: Array<Record<string, unknown>> = [];

  // eslint-disable-next-line array-callback-return
  payoutsArray.map((row) => {
    data.push({
      id: row?.id,
      status: row.status,
      externalId: row.external_id,
      amount: row.amount / 100,
      createdAt: dayjs(row.created_at).format('YYYY-MM-DD HH:mm'),
      createdAtFormatted: translatedMonthAndYear(row.created_at),
    });
  });

  const { pages, page } = payouts;
  return { noOfPages: pages, currentPage: page, data };
};

const payoutTransactionsSelector = (payout) => {
  // eslint-disable-next-line no-underscore-dangle
  const transactionsArray = payout._embedded?.items ?? [];
  return transactionsArray.map((row) => {
    return {
      id: row?.id,
      status: row.status,
      externalId: row.external_id,
      amount: row.amount / 100,
      amountAsString: row.amount_as_string ?? (row.amount / 100).toString(),
      appointmentId: row.appointment?.id,
      patientName: `${row.user?.first_name} ${row.user?.last_name}`,
      createdAt: dayjs(row.created_at).format('YYYY-MM-DD HH:mm'),
      consultationInvoiceId: row?.consultation_invoice?.media?.id,
      platformFeeInvoiceId: row?.platform_fee_invoice?.media?.id,
      consultationInvoiceStatus: row?.consultation_invoice?.media?.status,
      platformFeeInvoiceStatus: row?.platform_fee_invoice?.media?.status,
      platformFee: row?.platform_fee,
      platformFeeAsString: row?.platform_fee_as_string,
    };
  });
};

const incomeSelector = (income) => {
  // eslint-disable-next-line no-underscore-dangle
  const incomeArray = income._embedded?.items ?? [];
  const data: Array<Record<string, unknown>> = [];

  // eslint-disable-next-line array-callback-return
  incomeArray.map((row) => {
    data.push({
      // id: 200 * Math.random(),
      id: `${row.date_start} - ${row.date_end}`,
      startDate: row.date_start,
      endDate: row.date_end,
      amount: row.total_income,
      noOfAppointments: row.no_of_appointments,
    });
  });

  const { pages, page } = income;
  return { noOfPages: pages, currentPage: page, data };
};

const solveStatus = (status) => {
  switch (status) {
    case 'created':
      return `${i18next.t('states.created')}`;
    case 'profile_selected':
      return `${i18next.t('states.profile_selected')}`;
    case 'waiting_for_payment':
      return `${i18next.t('states.waiting_for_payment')}`;
    case 'searching_for_doctor':
      return `${i18next.t('states.searching_for_doctor')}`;
    case 'ready_to_start':
      return `${i18next.t('states.ready_to_start')}`;
    case 'rejected_by_doctor':
      return `${i18next.t('states.rejected_by_doctor')}`;
    case 'cancelled_search':
      return `${i18next.t('states.cancelled_search')}`;
    case 'deleted':
      return `${i18next.t('states.deleted')}`;
    case 'cancelled_by_patient':
      return `${i18next.t('states.cancelled_by_patient')}`;
    case 'in_progress':
      return `${i18next.t('states.in_progress')}`;
    case 'cancelled_by_doctor':
      return `${i18next.t('states.cancelled_by_doctor')}`;
    case 'call_ended':
      return `${i18next.t('states.call_ended')}`;
    case 'completed':
      return `${i18next.t('states.completed')}`;
    case 'cancelled':
      return `${i18next.t('states.cancelled')}`;
    case 'canceled':
      return `${i18next.t('states.canceled')}`;
    default:
      return status;
  }
};

const incomeTransactionsSelector = (transactions) => {
  return Object.entries(transactions).map(([index, transaction]) => {
    return {
      // @ts-ignore
      id: transaction?.id,
      // @ts-ignore
      priceGrossAsString: transaction.price_gross_as_string, // Initial Appointment Price
      // @ts-ignore
      incomeAsString: transaction.income_as_string, // Doctor income
      // @ts-ignore
      platformFeeAsString: transaction.platform_fee_as_string, // Platform fee
      // @ts-ignore
      voucher: transaction.voucher_value_as_string, // Voucher
      // @ts-ignore
      consultationInvoice: transaction.consultation_invoice, // Consultation Invoice
      // @ts-ignore
      consultationInvoiceId: transaction.consultation_invoice?.media?.id, // Consultation Invoice ID
      // @ts-ignore
      consultationInvoiceStatus: transaction.consultation_invoice?.media?.status, // Consultation Invoice Status
      // @ts-ignore
      platformFeeInvoice: transaction.platform_fee_invoice, // Platform fee invoice
      // @ts-ignore
      platformFeeInvoiceId: transaction.platform_fee_invoice?.media?.id, // Platform fee invoice ID
      // @ts-ignore
      platformFeeInvoiceStatus: transaction.platform_fee_invoice?.media?.status, // Platform fee invoice Status
      // @ts-ignore
      stornoConsultationInvoice: transaction.storno_consultation_invoice, // Storno Consultation Invoice
      // @ts-ignore
      stornoConsultationInvoiceId: transaction.storno_consultation_invoice?.media?.id, // Storno Consultation Invoice Id
      // @ts-ignore
      stornoConsultationInvoiceStatus: transaction.storno_consultation_invoice?.media?.status, // Storno Consultation Invoice Status
      // @ts-ignore
      stornoPlatformFeeInvoice: transaction.storno_platform_fee_invoice, // Storno Platform fee invoice
      // @ts-ignore
      stornoPlatformFeeInvoiceId: transaction.storno_platform_fee_invoice?.media?.id, // Storno Platform fee invoice Id
      // @ts-ignore
      stornoPlatformFeeInvoiceStatus: transaction.storno_platform_fee_invoice?.media?.status, // Storno Platform fee invoice Status
      // @ts-ignore
      createdAt: dayjs(transaction.created_at).format('YYYY-MM-DD HH:mm'),
      // @ts-ignore
      amountRefundedAsString: transaction.amount_refunded_as_string,
      // @ts-ignore
      patientName: `${transaction.patient_name}`,
      // @ts-ignore
      paymentStatus: solveStatus(transaction.payment_status),
      // @ts-ignore
      doctorName: transaction.doctor_name, // Voucher
    };
  });
};

const incomeTransactionsMonthlySelector = (monthly) => {
  return {
    totalIncome: parseFloat(monthly.total_income).toFixed(2),
    totalPlatformFee: parseFloat(monthly.total_platform_fee).toFixed(2),
  };
};

export {
  payoutsSelector,
  payoutTransactionsSelector,
  balanceSelector,
  incomeSelector,
  incomeTransactionsSelector,
  incomeTransactionsMonthlySelector,
};
