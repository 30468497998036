import 'doctor/styles/consultation-details.css';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import {
  cancelAppointmentByDoctorRequest,
  getAppointmentDetailsRequest,
} from 'shared/redux/actions/DoctorAppointmentsActions';
import { getDoctorAppointmentDetailsState } from 'shared/redux/src/StatesGetter';
import Utils from 'shared/modules/Utils';
import Alerts from 'shared/components/Alerts';
import CustomIcon from 'shared/components/CustomIcon';
import Colors from 'shared/themes/Colors';
import { useTranslation } from 'react-i18next';
import { translatedFullDate } from 'shared/modules/DateTimeUtils';
import dayjs from 'dayjs';

const DoctorConsultationDetailsTabInfo = (props) => {
  const { t } = useTranslation();
  const { templateType = 'future' } = props;
  const dispatch = useDispatch();

  const {
    date = dayjs().format('YYYY-MM-DD HH:mm:ss'),
    id,
    appointmentType = { type: '' },
    realDurationAsString,
    duration,
    serverStatus,
    status,
    priceGrossAsString,
  } = useSelector(getDoctorAppointmentDetailsState, shallowEqual);

  const cancelAppointment = (e) => {
    e.preventDefault();
    Alerts.okCancelAlert(
      `${t('warning')}`,
      `${t('validations.cancelAppointment')}`,
      () => dispatch(cancelAppointmentByDoctorRequest({ id, templateType })),
      () => {},
    );
  };
  const joinAppointment = (e) => {
    e.preventDefault();
    if (!Utils.rejoinableAppointment(serverStatus, date, duration)) {
      Alerts.simpleAlert(t('info'), t('alerts.tooLate'));
      return;
    }
    dispatch(
      getAppointmentDetailsRequest({
        id,
        updateLocal: true,
        alsoTwilio: true,
      }),
    );
  };
  const renderButton = () => {
    if (templateType === 'future') {
      if (Utils.rejoinableAppointment(serverStatus, date, duration)) {
        return (
          <Link to="/" onClick={joinAppointment} className="green-button start-consultation-btn">
            <CustomIcon className="custom-icon" color={Colors.white} size="24" icon="Video" />
            {t('appointments.startAppointment')}
          </Link>
        );
      }
      if (appointmentType.type !== 'free_talk') {
        return (
          <button
            type="button"
            onClick={cancelAppointment}
            className="white-button cancel-consultation-btn"
          >
            {t('appointments.cancelAppointment')}
          </button>
        );
      }
      return null;
    }
    return null;
  };
  return (
    <>
      {renderButton()}
      <div className="subtitle">{t('details')}</div>
      <div className="details-box">
        <ListGroup>
          <ListGroup.Item>
            <div className="title">{t('dateAndTime')}</div>
            <div className="details">{translatedFullDate(date, true, true)}</div>
          </ListGroup.Item>
          {serverStatus !== 'cancelled' && realDurationAsString > 0 && (
            <ListGroup.Item>
              <div className="title">{t('duration')}</div>
              <div className="details">{`${
                templateType === 'future' ? duration : realDurationAsString
              } min`}</div>
            </ListGroup.Item>
          )}
          {templateType === 'past' && (
            <>
              <ListGroup.Item>
                <div className="title">{t('state')}</div>
                <div className="details">{status}</div>
              </ListGroup.Item>
              <ListGroup.Item>
                <div className="title">{t('wallet.initialAppointmentFee')}</div>
                <div className="details">{priceGrossAsString}</div>
              </ListGroup.Item>
            </>
          )}
        </ListGroup>
      </div>
    </>
  );
};

DoctorConsultationDetailsTabInfo.defaultProps = {
  match: {},
  templateType: 'future',
};
DoctorConsultationDetailsTabInfo.propTypes = {
  templateType: PropTypes.string,
  match: PropTypes.shape({
    params: PropTypes.shape({}),
  }),
};
export default DoctorConsultationDetailsTabInfo;
